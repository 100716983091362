@import-normalize;
@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Slab&display=swap");
@import "breakpoint-sass";
@import "breakpoint-slicer";
/////////////////////////////////////////
// Setting up Breakpoint Slicer
/////////////////////////////////////////
$slicer-breakpoints: 0 400px 600px 800px 1050px;

//                          └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────
// Slice numbers:                 1             2             3             4             5
$slicer-breakpoint-names: "xs" "s" "m" "l" "xl";

// font-family: 'Roboto', sans-serif;
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: #434243;

  .gradient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
  .logo {
    height: 100px;
    width: 130px;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
    z-index: 999;
  }
}
